import { ThemeConfig } from '@/UI';
import * as colors from './themeColor';

type ITheme = {
  // eslint-disable-next-line no-unused-vars
  [key in keyof Omit<IColor, 'display'>]: Record<ThemeDisplayName, string>;
};

export type ThemeDisplayName = 'light' | 'dark';

export interface IColor {
  [key: string]: string;
}

// Get all theme colors from themeColor.ts and merge them into one object
const mergeTheme = (): ITheme => {
  if (!colors) return;

  return Object.values(colors).reduce((acc, cur) => {
    const { display, ...rest } = cur;
    const properties = Object.keys(rest).reduce((propsAcc, propsCur) => {
      return {
        ...propsAcc,
        [propsCur]: {
          ...acc[propsCur],
          [display]: rest[propsCur],
        },
      };
    }, {});
    return {
      ...acc,
      ...properties,
    };
  }, {} as ITheme);
};

// Key name in localStorage
export const COLOR_MODE = 'color-mode';

// Key name in CSS variable
export const INITIAL_COLOR_MODE = '--initial-color-mode';
export const COLORS = mergeTheme();

export const getAntdTheme: (c) => ThemeConfig = (c) => ({
  token: {
    fontSize: 16,
    fontFamily: 'inherit',
    colorPrimary: c?.primary,
    borderRadius: 6,
    colorBorder: c?.stroke,
    colorError: c?.error,
    controlHeight: 36,
    controlHeightXS: 28,
    controlHeightLG: 40,
    controlHeightSM: 32,
    colorTextPlaceholder: c?.['sub-text'],
    colorIcon: c?.['icon'],
  },
  components: {
    Modal: {
      paddingContentHorizontalLG: 0,
      paddingMD: 0,
      colorTextHeading: '#fff',
    },
    Spin: {
      colorPrimary: c?.primary,
    },
    Input: {
      colorBorder: c?.['stroke'],
    },
    Select: {
      colorBorder: c?.['stroke'],
    },
  },
});
