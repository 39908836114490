import { getImgByMimeType } from '@/configs/Chat/utils';
import { IMAGE_FALLBACK } from '@/constants';
import { Image } from '@/UI';
import Link from 'next/link';

interface AttachmentPreviewProps {
  attachment: any;
  length: number;
  onClickImg?: (id: string) => void;
}

const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({ attachment, length, onClickImg }) => {
  if (attachment?.mime?.includes('image/')) {
    return (
      <Image
        alt=""
        src={attachment?.url}
        preview={{ src: attachment?.url }}
        fallback={IMAGE_FALLBACK}
        height={length === 1 ? 150 : 100}
        width={length === 1 ? 150 : 100}
        className="object-cover rounded-lg col-span-1"
        onClick={() => onClickImg(attachment?.id)}
      />
    );
  }

  if (attachment?.mime?.includes('video/')) {
    return <video width="250" controls src={attachment?.url} className="w-full"></video>;
  }

  return (
    <Link href={attachment?.url} target="_blank" className="w-full">
      <div className="flex gap-2 p-2 rounded-lg bg-th-background border border-solid border-th-gray-100 text-th-text">
        <img src={getImgByMimeType(attachment?.mime)} alt="" width={40} height={40} />
        <div className="mr-3 overflow-hidden">
          <div className="truncate">{attachment?.name}</div>
          <div>{attachment?.ext?.toUpperCase()}</div>
        </div>
      </div>
    </Link>
  );
};

export default AttachmentPreview;
