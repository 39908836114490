import { useEffect, useState } from 'react';
import { WsListenerType } from '../constants';
import { IChannelID } from '../interfaces';
import { InternalStorage } from '../modules/InternalStorage';

interface IUseBaseChannel {
  channelID: IChannelID;
  subscribe: (params: {
    type: WsListenerType.CHANNEL_MESSAGE_MODE;
    listener: () => void;
    channelID: IChannelID;
  }) => any;
  internalStorage: InternalStorage;
}

export const useBaseChannelMsgMode = ({ channelID, internalStorage, subscribe }: IUseBaseChannel) => {
  const [_, reRender] = useState({});

  useEffect(() => {
    if (!channelID) return;

    const listener = () => reRender({});
    const unSubcribe = subscribe({ type: WsListenerType.CHANNEL_MESSAGE_MODE, listener, channelID });

    return () => {
      unSubcribe();
    };
  }, [channelID]);

  return internalStorage.data.channelMessageMode?.get(channelID);
};
