import React from 'react';
import { AvatarChat, CheckRound, CloseIconBig } from '@/components/icons';

export const WidgetHeader = ({ onClose }) => {
  return (
    <div className="px-3 flex justify-between gap-3 h-[64px] items-center shrink-0">
      <div className="flex gap-2 items-center shrink-0">
        <AvatarChat />
        <div className="flex flex-col">
          <p className="m-0 font-bold flex items-center">
            Gam - CSKH
            <CheckRound className="ml-2 bg-th-primary rounded-full " />
          </p>
          <p className="text-xs text-th-gray-400 m-0">Đang online</p>
        </div>
      </div>

      <div className="w-6 h-6 cursor-pointer flex items-center justify-center" onClick={onClose}>
        <CloseIconBig onClick={onClose} />
      </div>
    </div>
  );
};
