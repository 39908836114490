import { useEffect, useMemo } from 'react';
import { CHANNELS_LIMIT, WsListenerType } from '../constants';
import { IChannelData } from '../interfaces';
import { IGetChannelsParams, ROUTES } from '../services/gamp-chat.service';
import { ISubcribeParams } from '../modules/Emitter';
import { useLoadMore } from '@/hooks/useLoadMore';
import { useQueryClient } from '@tanstack/react-query';

interface IUseBaseChannels {
  params: {
    tag_id: IGetChannelsParams['tag_id'];
    keyword: IGetChannelsParams['keyword'];
  };
  subscribe: (params: ISubcribeParams) => any;
  onGetChannels: (params: IGetChannelsParams) => Promise<unknown>;
}

export const useBaseChannels = ({ subscribe, onGetChannels, params }: IUseBaseChannels) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => [ROUTES.GET_CHANNELS, 'list_channels', params?.tag_id, params?.keyword],
    [params?.keyword, params?.tag_id]
  );

  const { keyword, tag_id } = params || {};

  const { data, nodeRef, isFetching } = useLoadMore({
    queryKey,
    fetchFn: (pageParam: string) =>
      onGetChannels({
        before: pageParam,
        is_favorite: 1,
        tag_id: tag_id,
        keyword: keyword === '' ? undefined : keyword,
        limit: CHANNELS_LIMIT,
      }),
    initialPageParam: undefined,
    getNextPageParam: (channels) =>
      channels?.length < CHANNELS_LIMIT ? undefined : (channels?.[channels.length - 1] as any)?.score,
    staleTime: 0,
    gcTime: 0,
  });

  useEffect(() => {
    const listener = (data: any) => {
      queryClient.setQueryData(queryKey, (prev: any) => {
        const channelID = data?.channel_id;

        const newLastPages = prev?.pages?.at(-1).map((item) => {
          if (item.channel_id === channelID) {
            return {
              ...item,
              last_message: data,
            };
          }
          return item;
        });

        return {
          ...prev,
          pages: [...prev.pages.slice(0, -1), newLastPages],
        };
      });
    };
    const unSubcribe = subscribe({ type: WsListenerType.CHANNELS, listener });

    return () => {
      unSubcribe();
    };
  }, [queryKey, queryClient, subscribe]);

  const getChannels = (params: IGetChannelsParams) => onGetChannels({ is_favorite: 1, ...params });

  return { channels: data, getChannels, loading: isFetching, nodeRef };
};
