/* eslint-disable no-unused-vars */
import { WsListenerType } from '@/configs/Chat/constants';
import { Logger } from '@/configs/Chat/modules/Logger';
import { convertMessages, scrollToBottom } from '@/configs/Chat/utils';
import { InternalStorage } from '../modules/InternalStorage';
import { IChannelID } from '../interfaces';

enum EVENT_TYPES {
  MESSAGE = 'message',
}

type IEmitChanges = (type: WsListenerType, params: { channelID?: IChannelID; data?: any }) => void;

type IEvent = {
  channel: string;
  data: any;
  event: EVENT_TYPES;
};

type IEventReducer = (
  event: IEvent,
  internalStorage: InternalStorage,
  emitChanges: IEmitChanges,
  logger: Logger
) => void;

let timeout: NodeJS.Timeout;

export const eventReducer: IEventReducer = (eventData, internalStorage, emitChanges, logger) => {
  const { data, event } = eventData || {};
  const { misc } = internalStorage.data;

  switch (event) {
    case EVENT_TYPES.MESSAGE:
      const channelID = data?.channel_id;
      logger.info(`Event: ${event}`, data);

      const newMessages = convertMessages([data], misc);

      internalStorage.setChannelMessages(channelID, (prev) => {
        const currentMessages = prev.filter((m) => m.score);
        const sendingMessages = prev.filter((m) => !m.score);
        return [...currentMessages, ...newMessages, ...sendingMessages];
      });

      if (timeout) clearTimeout(timeout);

      // Emit changes after 100ms
      timeout = setTimeout(() => {
        emitChanges(WsListenerType.MESSAGES, { channelID });
        emitChanges(WsListenerType.CHANNELS, { data });

        // Scroll to bottom if the scroll bar distance is less than 100px
        scrollToBottom(channelID, 100);
      }, 150);

      break;

    default: {
      return;
    }
  }
};
