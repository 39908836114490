/* eslint-disable no-unused-vars */
import { useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef } from 'react';

type Props<T> = Omit<
  Parameters<typeof useInfiniteQuery<T[]>>[0],
  'queryFn' | 'initialPageParam' | 'getNextPageParam' | 'initialPageParam'
> & {
  fetchFn: (pageParam: number | string) => Promise<any>;
  pageSize?: number;
  getNextPageParam?: (lastPage: T[], allPages: T[][], lastPageParam: number) => number | undefined;
  initialPageParam?: number;
};

const defaultOptions: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};

export const useLoadMore = <T>({ queryKey, fetchFn, pageSize, ...options }: Props<T>) => {
  const observer = useRef<IntersectionObserver>(null);

  const nodeRef = useCallback((node: Element) => {
    if (!node || !observer.current) return;
    observer.current.observe(node);
  }, []);

  const { data, isFetching, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }: { pageParam: any }) => await fetchFn(pageParam),
    select: (res) => res,
    initialPageParam: undefined,
    getNextPageParam: (lastPage, _, lastPageParam: number) => {
      const lastPageLength = lastPage?.length || 0;
      if ((pageSize && lastPageLength < pageSize) || lastPageLength === 0) return undefined;

      return lastPageParam + 1;
    },
    ...options,
  });

  const createNewObserver = useCallback(() => {
    return new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && observer.current) {
        fetchNextPage();
        // unobserve when intersecting so that last item will not be observed to fetch again
        observer.current.unobserve(target.target);
      }
    }, defaultOptions);
  }, [fetchNextPage]);

  useEffect(() => {
    //  doesn't create new observer until options.enabled is true
    if (typeof options.enabled !== 'undefined' && !options.enabled) {
      observer.current = null;
      return;
    }
    observer.current = createNewObserver();
  }, [options?.enabled, createNewObserver]);

  const pageData = useMemo(() => data?.pages.flat(), [data]);

  return { data: pageData ?? [], isFetching, nodeRef, fetchNextPage };
};
