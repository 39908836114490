import { getDeviceInfo, OS } from '@/utils/device';
import { Button } from '@/UI';
import { useEffect, useState, useMemo } from 'react';

const OpenApp = () => {
  const [deviceOs, setDeviceOs] = useState<string>();

  useEffect(() => {
    const deviceInfo = getDeviceInfo();
    setDeviceOs(deviceInfo?.os);
  }, []);

  const isAndroid = useMemo(() => deviceOs === OS.android, [deviceOs]);
  const isIOS = useMemo(() => deviceOs === OS.iOS, [deviceOs]);

  const openApp = () => {
    const deepLink = isAndroid
      ? process.env.NEXT_PUBLIC_ANDROID_DEEP_LINK
      : process.env.NEXT_PUBLIC_IOS_DEEP_LINK;
    const storeLink = isAndroid ? process.env.NEXT_PUBLIC_GOOGLE_STORE : process.env.NEXT_PUBLIC_APP_STORE;

    window.location.href = deepLink;

    setTimeout(() => {
      if (document.hasFocus()) {
        window.location.href = storeLink;
      }
    }, 2000);
  };

  if (!isAndroid && !isIOS) return null;

  return (
    <Button size="small" ghost type="primary" onClick={openApp} className="w-24">
      Mở App
    </Button>
  );
};

export default OpenApp;
