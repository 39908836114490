import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { ErrorFallback } from './components/ErrorFallback';
import { Spin } from '@/UI';
import { IUserInfo } from '../interfaces';
import { APIResponse } from '@/interfaces';
import { ERROR_ROUTER, PUBLIC_ROUTER } from '../constants';
import { getSession, getUserInfo } from '@/services/Auth.service';
import { showMessage } from '@/components/messages/GMessage';

interface Props {
  children: React.ReactNode;
}

interface IAuthContext {
  handleSetUserInfo: (userInfo?: IUserInfo) => Promise<IUserInfo | void>;
  handleLogOut: (redirect?: string) => void;
  userInfo: IUserInfo;
}

const AuthContext = createContext<IAuthContext | null>(null);

const AuthProvider: React.FC<Props> = ({ children }) => {
  const router = useRouter();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>();
  const { error, error_description } = router.query;
  const pathname: string = router.pathname;
  const [userInfo, setUserInfo] = useState<IUserInfo | null>();

  useEffect(() => {
    if (ERROR_ROUTER.includes(pathname)) {
      localStorage.setItem('token', '1');
      localStorage.removeItem('token');
      return;
    }

    const handleGetSession = async () => {
      const res: APIResponse = await getSession();

      if (res.error && !PUBLIC_ROUTER.includes(pathname)) {
        localStorage.removeItem('access_token');
        window.location.href = '/';
      }

      const { access_token, device_id } = res?.data || {};

      if (access_token) {
        localStorage.setItem('token', access_token);
        localStorage.setItem('device_id', device_id);

        const userInfoRes: APIResponse = await getUserInfo();
        if (userInfoRes.error) {
          window.location.href = `/callback?error=${userInfoRes.error}`;
          return;
        }

        if (userInfoRes.data?.status === 'INIT' && pathname !== '/update-info') {
          window.location.href = `/update-info?identity=${userInfoRes.data?.identity}&name=${userInfoRes.data?.name}`;
          return;
        }

        if (userInfoRes.data?.status !== 'APPROVED' && pathname !== '/update-info') {
          handleLogOut('/signup/pageview');
          return;
        }

        setUserInfo(userInfoRes.data);
        setIsAuthenticated(true);
        if (PUBLIC_ROUTER.includes(pathname)) router.push('/home');
        return;
      }
    };

    if (pathname !== '/signup' && PUBLIC_ROUTER.includes(pathname) && isAuthenticated) {
      router.push('/');
      return;
    }

    if (!isAuthenticated) handleGetSession();
  }, [pathname]);

  const handleLogOut = (redirect = '/') => {
    if (typeof window === 'undefined') return;
    setIsAuthenticated(false);
    localStorage.clear();
    window.location.href = `${process.env.NEXT_PUBLIC_APP_URL}/api/auth/logout?redirect=${redirect}`;
  };

  const handleSetUserInfo = async (user?: IUserInfo) => {
    let newUserInfo = user;

    if (!newUserInfo) {
      const userInfoRes: APIResponse = await getUserInfo();
      if (userInfoRes.error) return showMessage.error(userInfoRes?.error);
      newUserInfo = userInfoRes.data;
    }

    setUserInfo(newUserInfo);
    return newUserInfo;
  };

  const authContextValue = useMemo(() => ({ handleLogOut, userInfo, handleSetUserInfo }), [userInfo]);

  if (
    !PUBLIC_ROUTER.includes(pathname) &&
    !ERROR_ROUTER?.includes(pathname) &&
    (!isAuthenticated || !userInfo)
  )
    return (
      <div className="flex h-screen items-center justify-center">
        <Spin />
      </div>
    );

  if (error)
    return <ErrorFallback error={error} error_description={error_description} handleLogOut={handleLogOut} />;

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
