import { CloseCircle } from '@/components/icons';
import { getImgByMimeType } from '@/configs/Chat/utils';
import { Image } from '@/UI';

interface IAttachmentPreview {
  index: number;
  attachment: File;
  onRemoveAttachment: (index: number) => void;
}

export const AttachmentUpload = ({ index, attachment, onRemoveAttachment }: IAttachmentPreview) => {
  const url = URL.createObjectURL(attachment);

  if (attachment.type.includes('image/')) {
    return (
      <div className="relative rounded-md">
        <Image alt="" width={80} height={80} src={url} preview={{ src: url }} className="rounded-lg" />
        <ButtonClose onRemove={() => onRemoveAttachment(index)} />
      </div>
    );
  }

  return (
    <div className="preview-file bg-th-gray-150 rounded-lg relative w-[80px] h-[80px] p-2">
      <div className="flex flex-col items-center justify-center">
        <img src={getImgByMimeType(attachment.type)} alt="" width={40} height={40} />
        <div className="file-name text-[13px] truncate max-w-[75px] mt-1">{attachment?.name}</div>
      </div>

      <ButtonClose onRemove={() => onRemoveAttachment(index)} />
    </div>
  );
};

const ButtonClose = ({ onRemove }) => {
  return (
    <div className="closeable absolute top-1 right-1">
      <CloseCircle className="text-th-gray-400 cursor-pointer" onClick={onRemove} />
    </div>
  );
};
