import { CACHE_MESSAGES_MS } from '../constants';
import { IChannelID, IChannelMessage, IChannelMessages, IMisc, ISetChannelMessages } from '../interfaces';
import { trueTypeOf } from '../utils';
import { Logger } from './Logger';

export enum IChannelMessageMode {
  BEFORE = 'before',
  AFTER = 'after',
  MAX_BEFORE = 'max_before',
  MAX_AFTER = 'max_after',
}

export class InternalStorage {
  private logger: Logger;
  private timeoutIDs: Map<IChannelID, NodeJS.Timeout>;

  private channelMessages: IChannelMessages;
  private currentSubscribedChannelID: IChannelID[];
  private channelMessageMode: Map<IChannelID, IChannelMessageMode[]>;
  private misc: IMisc;

  constructor(_logger: Logger) {
    this.logger = _logger;
    this.timeoutIDs = new Map();

    this.currentSubscribedChannelID = [];
    this.channelMessages = new Map();
    this.channelMessageMode = new Map();
    this.misc = {
      token: '',
      user: null,
      groupPath: null,
      currentChannelParamsHash: '',
    };
  }

  public setCurrentSubscribedChannelID = (newChannels: any) => {
    if (trueTypeOf(newChannels) === 'function') {
      this.currentSubscribedChannelID = (newChannels as (prev: IChannelID[]) => IChannelID[])(
        this.currentSubscribedChannelID
      );
      return;
    }

    this.currentSubscribedChannelID = newChannels;
  };

  public setChannelMessageMode = (
    channelID: IChannelID,
    mode: IChannelMessageMode[] | ((prev: IChannelMessageMode[]) => IChannelMessageMode[])
  ) => {
    if (trueTypeOf(mode) === 'function') {
      const prevModes = this.channelMessageMode.get(channelID) || [];
      const newModes = (mode as (prev: IChannelMessageMode[]) => IChannelMessageMode[])(prevModes);
      this.channelMessageMode.set(channelID, newModes);

      return;
    }

    this.channelMessageMode.set(channelID, mode as IChannelMessageMode[]);
  };

  public setChannelMessages: ISetChannelMessages = (channelID, messages) => {
    if (trueTypeOf(messages) === 'function') {
      const prevMessages = this.channelMessages.get(channelID) || [];
      const newMessages = (messages as (prev: IChannelMessage[]) => IChannelMessage[])(prevMessages);
      this.channelMessages.set(channelID, newMessages);
      return;
    }

    this.channelMessages.set(channelID, messages as IChannelMessage[]);
  };

  public setMisc = (fn: (misc: IMisc) => IMisc) => {
    this.misc = fn(this.misc);
  };

  public removeMessagesCache = (channelID: string) => {
    const id = setTimeout(() => {
      this.channelMessages.delete(channelID);
    }, CACHE_MESSAGES_MS);

    this.timeoutIDs.set(channelID, id);
  };

  public removeTimeoutIfExist = (channelID: string) => {
    if (this.timeoutIDs.has(channelID)) {
      clearTimeout(this.timeoutIDs.get(channelID));
      this.timeoutIDs.delete(channelID);
    }
  };

  get data() {
    return {
      currentSubscribedChannelID: this.currentSubscribedChannelID,
      channelMessageMode: this.channelMessageMode,
      channelMessages: this.channelMessages,
      misc: this.misc,
    };
  }
}
