import ApiClient from '@/configs/ApiClient';

const APP_URL = process.env.NEXT_PUBLIC_APP_URL;
const IAM_URL = process.env.NEXT_PUBLIC_GAM_IAM_URL;

const api = new ApiClient(`${APP_URL}/api/auth`).getInstance();
const iamApi = new ApiClient(`${IAM_URL}/api/v1`).getInstance();

export const endpoints = {
  login: '/login',
  loginWith3rdParty: 'login-with-3rd-party',
  callback: '/callback',
  session: '/session',
  logout: '/logout',
  refresh_token: '/refresh_token',
  sending_opt: '/users/login/otp/send',
  forgot_password: '/users/forgot-password',
  users: '/users',
  user_info: '/users/userinfo',
  getAnonymousToken: '/get-anonymous-token',
  updateAuthStatus: '/auth',
  uploadAvatar: '/users/avatar',
  register: '/users/create',
};

interface ILogin {
  otp?: string;
  identity?: string;
  password?: string;
  type: 'basic' | 'otp';
}

interface IRegister {
  email: string;
  phone: string;
  company_name: string;
  tax_code: string;
  name: string;
}

export const login = (params: ILogin) => {
  return api.post(endpoints.login, params);
};

export const getSession = () => {
  return api.get(endpoints.session);
};

export const loginWith3rdParty = (params: { type: string }) => {
  return api.post(endpoints.loginWith3rdParty, params);
};

export const createUser = (params) =>
  iamApi.put(endpoints.users, params, { headers: { 'X-Platform-Type': 'web' } });

export const getUserInfo = () => iamApi.get(endpoints.user_info);

export const getAnonymousToken = () => api.get(endpoints.getAnonymousToken);

export interface IUpdateAuthStatus {
  auth_profile_id: number;
  status?: string;
  active?: boolean;
}

export const updateAuthStatus = (params: IUpdateAuthStatus) => iamApi.put(endpoints.updateAuthStatus, params);

export const uploadAvatar = async (formData: FormData) =>
  iamApi.post(endpoints.uploadAvatar, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const register = (params: IRegister) =>
  iamApi.post(endpoints.register, params, { headers: { 'X-Platform-Type': 'web' } });

interface IForgotPass {
  username: 'email' | 'phone';
  new_password: string;
  confirm_password: string;
}

export const forgotPass = (params: IForgotPass, otp: string) =>
  iamApi.put(endpoints.forgot_password, params, {
    headers: {
      'X-Device-Id': localStorage.getItem('device_id'),
      'X-App-Version': process.env.buildId,
      'X-Otp-Code': otp,
    },
  });
