import { CloseIcon } from '@/components/icons';
import classNames from 'classnames';

interface IProps {
  label: string;
  onCancel: () => void;
  className?: string;
}

export default function Title(props: IProps) {
  return (
    <div
      className={classNames(
        'bg-th-white flex items-center text-medium py-5 border-0 border-b border-solid border-th-stroke',
        props.className
      )}
    >
      <CloseIcon
        id="close-icon"
        className="absolute right-5 hover:cursor-pointer text-th-sub-text"
        onClick={props.onCancel}
      />
      <p className="w-full text-center font-medium mb-0 text-th-text text-lg">{props.label}</p>
    </div>
  );
}
