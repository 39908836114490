import React, { useEffect, useState } from 'react';
import { Input } from '@/UI';
import classNames from 'classnames';
import { InputProps } from 'antd/es/input';
import { CloseIcon, EyeIcon, EyeInvisibleIcon } from '@/components/icons';

interface IProps extends InputProps {
  bordered?: boolean;
  type?: 'text' | 'password';
  allowClear?: boolean;
}

const CustomInput = ({
  placeholder,
  className = '',
  bordered = true,
  value,
  onChange,
  type = 'text',
  allowClear = false,
  ...props
}: IProps) => {
  const [inputValue, setInputValue] = useState(value);
  const [showPassword, setShowPassword] = useState(false);

  const handleChanged = (e) => {
    setInputValue(e.target.value);
    onChange?.(e.target.value);
  };

  const handleClear = () => {
    setInputValue('');
    onChange?.(undefined);
  };

  const handleShowPassword = () => setShowPassword((prev) => !prev);

  useEffect(() => {
    if (value === undefined) return;
    setInputValue(value);
  }, [value]);

  return (
    <div className={`relative flex flex-col w-full bg-th-background rounded-md group ${className}`}>
      {inputValue ? (
        <div className="z-10 text-xs text-th-text-soft absolute top-2 left-3 pointer-events-none">
          {placeholder}
        </div>
      ) : null}
      <Input
        type={showPassword ? 'text' : type}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleChanged}
        {...props}
        allowClear={false}
        className={classNames('flex-1  min-h-[56px]', {
          'border-none focus:border-th-primary': !bordered && !inputValue,
          'pt-6': inputValue,
        })}
      />

      {type === 'password' && (
        <div
          className="absolute z-10 right-3 my-auto h-full flex items-center justify-center text-th-gray-700 cursor-pointer hover:text-th-text"
          onClick={handleShowPassword}
        >
          {showPassword ? <EyeIcon /> : <EyeInvisibleIcon />}
        </div>
      )}
      {allowClear && inputValue && (
        <span
          className="absolute z-10 right-3 my-auto h-full hidden  group-hover:flex items-center justify-center text-th-gray-700 cursor-pointer hover:text-th-text"
          onClick={handleClear}
        >
          <CloseIcon />
        </span>
      )}
    </div>
  );
};

export default CustomInput;
