import { Button } from '@/UI';
import { ReactElement } from 'react';

interface ILoginButton3rd {
  icon: ReactElement;
  text: string;
  onClick?: () => void;
}

const LoginButton3rd = ({ icon, text, onClick }: ILoginButton3rd) => (
  <Button
    icon={icon}
    onClick={onClick}
    className="flex justify-center items-center rounded-lg cursor-pointer h-[3em] w-full px-2 py-3 border-none bg-white hover:text-th-primary hover:border-primary"
  >
    <span className="hidden md:flex items-center justify-center justify-self-center font-medium">{text}</span>
  </Button>
);

export default LoginButton3rd;
