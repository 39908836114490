import Title from '@/components/common/modal/Title';
import { Button, Form, Modal, Radio } from '@/UI';
import CustomInput from '@/UI/CustomInput';
import { useOtp } from '@/provider/otpProvider';
import { useState } from 'react';
import { forgotPass } from '@/services/Auth.service';
import { showMessage } from '@/components/messages/GMessage';
import {
  passwordConfirmValidation,
  passwordValidation,
  validateUsername,
} from '@/modules/settings/validations';
import { uid } from '@/utils/functions';

export const ForgottenPassword = ({ onClose, open, onOpenCSKH }) => {
  const [isNext, setIsNext] = useState(false);
  const { getOTP } = useOtp();
  const [form] = Form.useForm();

  const onSubmit = async () => {
    await form.validateFields();

    if (!isNext) {
      setIsNext(true);
      return;
    }

    const deviceId = localStorage.getItem('device_id');
    if (!deviceId) localStorage.setItem('device_id', uid(20));

    const value = form.getFieldsValue(true);

    const otp = await getOTP(value?.username);
    if (!otp) return;

    const res = await forgotPass(value, otp);
    if (res.error) return showMessage.error(res.error || 'Có lỗi xảy ra');

    showMessage.success(res.message || 'Đổi mật khẩu thành công');
    handleClose();
  };

  const handleClose = () => {
    setIsNext(false);
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      className="custom-login-modal"
      title={<Title label="Quên mật khẩu" onCancel={handleClose} />}
      footer={null}
      closable={false}
      onCancel={handleClose}
      width={500}
      open={open}
      centered
      destroyOnClose
    >
      <Form form={form} className="p-5">
        {!isNext ? (
          <>
            <p className="mb-2 font-medium">
              Vui lòng nhập Số điện thoại/Email tài khoản muốn khôi phục mật khẩu
            </p>
            <Form.Item
              name="username"
              rules={[
                ...validateUsername,
                {
                  required: true,
                  message: 'Vui lòng nhập Email hoặc SĐT',
                },
              ]}
            >
              <CustomInput placeholder="Email/SĐT" />
            </Form.Item>
            <p className="mb-2 font-medium">Chọn phương thức nhập mã OTP</p>
            <Form.Item
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn phương thức nhập mã OTP',
                },
              ]}
            >
              <Radio.Group>
                <Radio value="phone"> Số điện thoại </Radio>
                <Radio value="email"> Địa chỉ email </Radio>
              </Radio.Group>
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name="new_password"
              rules={[
                ...passwordValidation,
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu mới',
                },
              ]}
            >
              <CustomInput type="password" placeholder="Nhập mật khẩu mới" />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                ...passwordConfirmValidation,
                {
                  required: true,
                  message: 'Vui lòng nhập lại mật khẩu',
                },
              ]}
            >
              <CustomInput type="password" placeholder="Nhập lại mật khẩu" />
            </Form.Item>
          </>
        )}
        <Form.Item>
          <div className="grid grid-cols-4 gap-2">
            <Button onClick={onOpenCSKH} className="h-[44px]">
              Chat CSKH
            </Button>
            <Button className="col-span-3 h-[44px]" type="primary" onClick={onSubmit}>
              Tiếp tục
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
