import { Form } from '@/UI';
import * as yup from 'yup';

type IRules = React.ComponentProps<typeof Form.Item>['rules'];

const TELEPHONE_REGEX =
  /^((08|09|03|07|05|04|02[0123456789]|06)|(([+]{0,1}84)(1[2689]|8|9|3|7|5|4|2[0123456789]|6)))[0-9]{8}$/;
const NUMBER_REGEX = /^\d+$/;

// Only allow A-Z, a-z, Vietnamese letters , space, -, ', .
const CONTACT_NAME_REGEX = /^[A-Za-zÀ-ỹ\s\-',.]+$/;
const EMAIL_REGEX = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const VALIDATION_MESSAGE = {
  password:
    'Mật khẩu tối thiểu 8 ký tự, phải bao gồm: chữ hoa, chữ thường, chữ số, ký tự đặc biệt như $,#,@,+,-,=,?,!.',
  confirm_password: 'Mật khẩu không khớp',
};

const PAYMENT_VALIDATION = {
  required: 'Vui lòng nhập trường thông tin',
  max: (max: number) => `Vui lòng nhập tối đa ${max} ký tự`,
  min: (min: number) => `Vui lòng nhập tối thiểu ${min} ký tự`,
  telephone: 'Số điện thoại không hợp lệ',
  number: 'Vui lòng nhập số',
  whitespace: 'Vui lòng không nhập khoảng trắng',

  full_name: 'Tên người liên hệ không hợp lệ',
  email_contact: 'Email không hợp lệ',
  account_holder: 'Chủ tài khoản không hợp lệ',
};

const validations = {
  name_contact: yup
    .string()
    .max(50, PAYMENT_VALIDATION.max(50))
    .test('text', PAYMENT_VALIDATION.full_name, (value) => {
      if (value.trim() === '') return false;
      return CONTACT_NAME_REGEX.test(value);
    }),
  phone_contact: yup.string().matches(TELEPHONE_REGEX, PAYMENT_VALIDATION.telephone),
  email_contact: yup.string().matches(EMAIL_REGEX, PAYMENT_VALIDATION.email_contact),
  bank_account_name: yup
    .string()
    .max(50, PAYMENT_VALIDATION.max(50))
    .test('text', PAYMENT_VALIDATION.account_holder, (value) => {
      if (value.trim() === '') return false;
      return CONTACT_NAME_REGEX.test(value);
    }),
  bank_account_number: yup
    .string()
    .min(10, PAYMENT_VALIDATION.min(10))
    .max(18, PAYMENT_VALIDATION.max(18))
    .matches(NUMBER_REGEX, PAYMENT_VALIDATION.number),
};

const schema = yup.object().shape(validations);

export const getFieldValidations: (field: string) => IRules = (field) => {
  return [
    {
      async validator(_, value) {
        if (validations[field] === undefined || !value) {
          return;
        }
        await schema.validateSyncAt(field, { [field]: value });
      },
    },
  ];
};

export const passwordValidation: IRules = [
  {
    required: true,
    message: 'Vui lòng nhập mật khẩu',
  },
  {
    min: 8,
    message: 'Mật khẩu phải có ít nhất 8 ký tự',
  },
  {
    pattern: new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])'),
    message: VALIDATION_MESSAGE.password,
  },
];

export const passwordConfirmValidation: IRules = [
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('new_password') === value) {
        return Promise.resolve();
      }

      return Promise.reject(VALIDATION_MESSAGE.confirm_password);
    },
  }),
];

export const validateUsername: IRules = [
  ({ getFieldValue }) => ({
    async validator(_, value) {
      const type = getFieldValue('type');

      if (type === 'email' && !EMAIL_REGEX.test(value)) {
        return Promise.reject('Email không hợp lệ');
      }

      if (type === 'phone' && !TELEPHONE_REGEX.test(value)) {
        return Promise.reject('SĐT không hợp lệ');
      }

      return Promise.resolve();
    },
  }),
];
