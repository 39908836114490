import React from 'react';
import { ChatContainer } from './ChatContainer';

interface IWidgetChat {
  open: boolean;
  onClose: () => void;
}

export const WidgetChat: React.FC<IWidgetChat> = ({ open, onClose }) => {
  if (!open) return;
  return <ChatContainer onClose={onClose} />;
};
