import Title from '@/components/common/modal/Title';
import { showMessage } from '@/components/messages/GMessage';
import { useAuth } from '@/configs/Auth/client';
import { IGroupPath } from '@/configs/Chat/interfaces';
import { getChannelsInfo } from '@/configs/Chat/services/gamp-chat.service';
import { createOtp } from '@/services/ShopDetail.service';
import { Button, Input, Modal } from '@/UI';
import { useRouter } from 'next/router';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

interface IOtpProviderProps {
  children: React.ReactNode;
}

interface IOtpContext {
  getOTP: (username?: string) => Promise<string>;
  createOtpStatus: boolean;
  setCreateOtpStatus: (status: boolean) => void;
}

const Context = React.createContext<IOtpContext>(null);

export const useOtp = () => {
  const value = useContext(Context);

  if (!value) {
    throw new Error('useOtp must be used within a OtpProvider');
  }

  return value as Omit<IOtpContext, 'setCreateOtpStatus' | 'createOtpStatus'>;
};

export const OtpProvider: React.FC<IOtpProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const promise = useRef<(value: string) => void>();
  const [tempUsername, setTempUsername] = useState<string>();
  const [inputValue, setInputValue] = useState([]);
  const [createOtpStatus, setCreateOtpStatus] = useState<boolean>(true);
  const { userInfo } = useAuth();

  const getOTP = useCallback(
    async (username?: string) => {
      setOpen(true);

      const res = await createOtp({
        username: username || userInfo?.identity,
      });

      if (!res?.success) {
        showMessage.error(res?.error || 'Có lỗi xảy ra');
        setCreateOtpStatus(false);
      }

      setTempUsername(username || userInfo?.identity);

      return new Promise((resolve) => (promise.current = resolve)) as Promise<string>;
    },
    [userInfo]
  );

  const onClose = () => {
    setOpen(false);
    // Might be undefined
    promise.current?.(null);
    setInputValue([]);
  };

  const onChangeInput = (values) => {
    setInputValue(values);
  };

  const onSubmit = () => {
    setOpen(false);
    promise.current(inputValue.join(''));
    setInputValue([]);
  };

  const value = useMemo(() => ({ getOTP, setCreateOtpStatus, createOtpStatus }), [getOTP, createOtpStatus]);

  return (
    <Context.Provider value={value}>
      {children}
      <Modal
        width={450}
        open={open}
        closable={false}
        onCancel={onClose}
        title={<Title label="Xác thực bảo mật" onCancel={onClose} />}
        footer={null}
        destroyOnClose
        centered
      >
        <CountDownContent
          onChangeInput={onChangeInput}
          values={inputValue}
          onSubmit={onSubmit}
          onClose={onClose}
          tempUsername={tempUsername}
        />
      </Modal>
    </Context.Provider>
  );
};

interface ICoutDownContentProps {
  onChangeInput: (values: any) => void;
  values: any;
  onSubmit: () => void;
  onClose: () => void;
  tempUsername: string;
}

const CountDownContent: React.FC<ICoutDownContentProps> = ({
  onChangeInput,
  values,
  onSubmit,
  onClose,
  tempUsername,
}) => {
  const ref = useRef<any>();
  const router = useRouter();
  const { createOtpStatus } = useContext(Context);
  const { userInfo } = useAuth();

  useEffect(() => {
    if (!ref.current) return;

    const ele = ref.current;

    const onInput = (ele) => {
      if (ele.value) {
        ele.classList.add('has-value');
      } else {
        ele.classList.remove('has-value');
      }
    };

    ele.querySelectorAll('input').forEach((input) => {
      input.addEventListener('input', () => onInput(input));
    });

    return () => {
      ele.querySelectorAll('input').forEach((input) => {
        input.removeEventListener('input', () => onInput(input));
      });
    };
  }, []);

  const handlePaste = (e) => {
    e.preventDefault();
    const value = e.clipboardData.getData('text');
    onChangeInput(value.split(''));
    ref.current.querySelectorAll('input').forEach((input) => {
      input.classList.add('has-value');
    });
  };

  const contactCSKH = async () => {
    onClose();

    const { error, data } = await getChannelsInfo({
      is_favorite: 1,
      group_path: IGroupPath.CHAT_GAM,
      channel_id: `user_cskh_${userInfo.user_id}`,
    });
    if (error) return showMessage.error(error);
    if (data?.[0]?.channel_id) router.push(`/channel/${data?.[0]?.channel_id}`);
  };

  return (
    <div className="-mt-[8px] p-4 modal-otp" ref={ref}>
      <div className="font-semibold text-center">
        Mã OTP đã được gửi về email bạn đã đăng ký. Vui lòng kiểm tra và điền mã
      </div>
      <div className="w-full flex flex-col items-center justify-center pb-8 pt-4">
        <Input.OTP
          value={values.join('')}
          length={6}
          onInput={onChangeInput}
          disabled={!createOtpStatus}
          onPaste={handlePaste}
        />
        <CountDown tempUsername={tempUsername} />
      </div>

      <div className="flex gap-2 justify-center">
        {userInfo && userInfo?.user_type !== 'ANONYMOUS' && (
          <Button className="w-1/3" onClick={contactCSKH}>
            Chat CSKH
          </Button>
        )}
        <Button className="w-2/3" type="primary" disabled={values.length !== 6} onClick={onSubmit}>
          Tiếp tục
        </Button>
      </div>
    </div>
  );
};

const CountDown = ({ tempUsername }) => {
  const [time, setTime] = useState<number>(30);
  const ref = useRef<any>();
  const { setCreateOtpStatus, createOtpStatus } = useContext(Context);

  const handleResend = async () => {
    const res = await createOtp({
      username: tempUsername,
    });
    setTime(30);

    ref.current = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);

    if (!res?.success) {
      showMessage.error(res?.error || 'Có lỗi xảy ra');
      setCreateOtpStatus(false);
      return;
    }

    setCreateOtpStatus(true);
  };

  useEffect(() => {
    if (time === 0) {
      clearInterval(ref.current);
    }
  }, [time]);

  useEffect(() => {
    ref.current = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(ref.current);
    };
  }, []);

  return (
    <div className="text-center text-th-gray-500 text-sm mt-2">
      {time === 0 ? (
        <button className="text-th-primary" onClick={handleResend}>
          Gửi lại mã
        </button>
      ) : (
        <div>
          {!createOtpStatus && (
            <div className="my-1 text-th-red-500 text-sm">
              Đã có lỗi xảy ra khi gửi mã OTP vui lòng thử lại sau
            </div>
          )}
          Yêu cầu gửi lại sau {time}s
        </div>
      )}
    </div>
  );
};
