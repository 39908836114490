export { default as ArrowDownIcon } from '@/components/icons/ArrowDown.svg';
export { default as ArrowRightIcon } from '@/components/icons/ArrowRight.svg';
export { default as CloseIcon } from '@/components/icons/CloseIcon.svg';
export { default as LogoutIcon } from '@/components/icons/LogoutIcon.svg';
export { default as Check } from '@/components/icons/Check.svg';
export { default as RadioCheck } from '@/components/icons/RadioCheck.svg';
export { default as GAM } from '@/components/icons/GAM.svg';
export { default as Setting } from '@/components/icons/Setting.svg';
export { default as Search } from '@/components/icons/Search.svg';
export { default as EyeIcon } from '@/components/icons/EyeIcon.svg';
export { default as EyeInvisibleIcon } from '@/components/icons/EyeInvisibleIcon.svg';
export { default as Moon } from '@/components/icons/Moon.svg';
export { default as Sun } from '@/components/icons/Sun.svg';
export { default as PlusIcon } from '@/components/icons/Plus.svg';
export { default as TrashIcon } from '@/components/icons/Trash.svg';
export { default as CheveronDown } from '@/components/icons/CheveronDown.svg';
export { default as CloseCircle } from '@/components/icons/CloseCircle.svg';
export { default as DeleteIcon } from '@/components/icons/DeleteIcon.svg';
export { default as ArrowLeft } from '@/components/icons/ArrowLeft.svg';
export { default as Plus } from '@/components/icons/Plus.svg';
export { default as Warning } from '@/components/icons/Warning.svg';
export { default as GoogleIcon } from '@/components/icons/Google.svg';
export { default as GHTKIcon } from '@/components/icons/GHTK.svg';
export { default as AppleIcon } from '@/components/icons/Apple.svg';
export { default as GroupIcon } from '@/components/icons/GroupIcon.svg';
export { default as LogoIcon } from '@/components/icons/LogoIcon.svg';
export { default as UserOutlined } from '@/components/icons/UserOutlined.svg';
export { default as PencilIcon } from '@/components/icons/PencilIcon.svg';
export { default as LegendChartIcon } from '@/components/icons/LegendChart.svg';
export { default as LoveFaceIcon } from '@/components/icons/LoveFace.svg';
export { default as PartyPopperIcon } from '@/components/icons/PartyPopper.svg';
export { default as CheckRound } from '@/components/icons/CheckRound.svg';
export { default as EmoijiIcon } from '@/components/icons/EmoijiIcon.svg';
export { default as AvatarChat } from '@/components/icons/AvatarChat.svg';
export { default as AddPhotoAlternate } from '@/components/icons/AddPhotoAlternate.svg';
export { default as PaperClip } from '@/components/icons/PaperClip.svg';
export { default as SendMessageIcon } from '@/components/icons/SendMessageIcon.svg';
export { default as AddWrapper } from '@/components/icons/AddWrapper.svg';
export { default as CloseIconBig } from '@/components/icons/CloseIconBig.svg';
export { default as SearchSetting } from '@/components/icons/SearchSetting.svg';
export { default as ThreeDots } from '@/components/icons/ThreeDots.svg';
export { default as ChatBell } from '@/components/icons/ChatBell.svg';
export { default as ChatFile } from '@/components/icons/ChatFile.svg';
export { default as ChatImg } from '@/components/icons/ChatImg.svg';
export { default as ChatLink } from '@/components/icons/ChatLink.svg';
export { default as ChatSave } from '@/components/icons/ChatSave.svg';
export { default as ChatUser } from '@/components/icons/ChatUser.svg';
export { default as ChatPrimaryImg } from '@/components/icons/ChatPrimaryImg.svg';
export { default as ChatPrimaryEmoji } from '@/components/icons/ChatPrimaryEmoji.svg';
export { default as ChatPrimarySticker } from '@/components/icons/ChatPrimarySticker.svg';
export { default as ArrowLeftAlt } from '@/components/icons/ArrowLeftAlt.svg';
export { default as Camera } from '@/components/icons/Camera.svg';
export { default as HomeIcon } from '@/components/icons/Home.svg';
export { default as MessageIcon } from '@/components/icons/Message.svg';
export { default as NotiIcon } from '@/components/icons/Noti.svg';
export { default as CalendarIcon } from '@/components/icons/Calendar.svg';
export { default as TickMsgIcon } from '@/components/icons/TickMsg.svg';
export { default as CallbotIcon } from '@/components/icons/Callbot.svg';
export { default as StarIcon } from '@/components/icons/Star.svg';
export { default as QuetDonHangIcon } from '@/components/icons/QuetDonHang.svg';
export { default as NhapHangIcon } from '@/components/icons/NhapHang.svg';
export { default as XuatHangIcon } from '@/components/icons/XuatHang.svg';
export { default as KiemHangIcon } from '@/components/icons/KiemHang.svg';
export { default as PrintIcon } from '@/components/icons/Print.svg';
export { default as GiucGiaoIcon } from '@/components/icons/GiucGiao.svg';
export { default as GiucLayIcon } from '@/components/icons/GiucLay.svg';
export { default as QuetLabelIcon } from '@/components/icons/QuetLabel.svg';
export { default as ChatCSKHIcon } from '@/components/icons/ChatCSKH.svg';
export { default as EditIcon } from '@/components/icons/Edit.svg';
export { default as InfoIcon } from '@/components/icons/Info.svg';
