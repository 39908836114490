import { chatInstance, useChannelMsgMode } from '@/configs/Chat';
import { MESSAGES_LIMIT } from '@/configs/Chat/constants';
import dayts from '@/utils/time';
import { Skeleton, Image, Space } from '@/UI';
import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Message } from './Message';
import { IChannelMessageMode } from '@/configs/Chat/modules/InternalStorage';
import { ArrowDownIcon } from '@/components/icons';
import { SmallLoader } from '@/components/common/SmallLoader';
import cn from 'classnames';

export const ChatWrapper = ({ channelMessages, channelID }) => {
  const [current, setCurrent] = useState('');
  const mode = useChannelMsgMode(channelID);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);

  useEffect(() => {
    const element = containerRef.current;

    const cb = async () => {
      const isBefore = element.scrollHeight + element.scrollTop - element.clientHeight < 20;
      const isAfter = element.scrollTop > -20;

      if (!loading) {
        if (mode.includes(IChannelMessageMode.BEFORE) && isBefore) {
          setLoading(true);
          await chatInstance.handleGetMessages({
            channel_id: channelID,
            limit: MESSAGES_LIMIT,
            before: true,
          });
          element.scrollTop = element.scrollTop + 30;
          setLoading(false);
        }

        if (mode.includes(IChannelMessageMode.AFTER) && isAfter) {
          setLoading(true);
          await chatInstance.handleGetMessages({
            channel_id: channelID,
            limit: MESSAGES_LIMIT,
            after: true,
          });
          element.scrollTop = element.scrollTop - 30;
          setLoading(false);
        }
      }

      setShowScrollToBottom(element.scrollTop < -200 || mode.includes(IChannelMessageMode.AFTER));
    };

    element.addEventListener('scroll', cb);

    return () => {
      element.removeEventListener('scroll', cb);
    };
  }, [loading, mode, channelID]);

  const imageList = useMemo(() => {
    return channelMessages?.reduce((acc, message) => {
      if (message?.attachments?.length) {
        const images = message.attachments.filter((attachment) => attachment.mime.includes('image/'));
        return [
          ...acc,
          ...images.map((image) => ({
            url: image.url,
            id: image.id,
          })),
        ];
      }
      return acc;
    }, []);
  }, [channelMessages]);

  const onClickImg = (id) => {
    const index = imageList.findIndex((image) => image.id === id);
    setCurrent(imageList[index].url);
  };

  const onDownload = () => {
    const suffix = current.slice(current.lastIndexOf('.'));
    const filename = Date.now() + suffix;

    fetch(current)
      .then((response) => response.blob())
      .then((blob) => {
        const blobUrl = URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        link.remove();
      });
  };

  const handleScrollToBottom = async () => {
    const element = containerRef.current;

    if (mode.includes(IChannelMessageMode.AFTER)) {
      await chatInstance.handleGetMessages(
        {
          channel_id: channelID,
          limit: MESSAGES_LIMIT,
        },
        [IChannelMessageMode.BEFORE, IChannelMessageMode.MAX_AFTER]
      );
    }

    element.scrollTo({
      top: element.scrollHeight,
    });
  };

  return (
    <div
      className="px-3 pt-1 overflow-auto h-full relative flex flex-col-reverse"
      id={`chat-wrapper-${channelID}`}
      ref={containerRef}
    >
      {loading && (
        <div className="w-full flex flex-col gap-2">
          <div className="w-full flex justify-start">
            <Skeleton.Input active className="w-[80%]" />
          </div>
          <div className="w-full flex justify-end">
            <Skeleton.Input active className="w-[80%] text-right" />
          </div>
        </div>
      )}
      <div
        className={cn(
          `absolute -bottom-10 w-[32px] h-[32px] bg-th-gray-50 rounded-full left-1/2 -translate-x-1/2 cursor-pointer 
          z-50 border border-solid border-th-gray-100 flex items-center justify-center transition-all`,
          {
            '!bottom-4': showScrollToBottom || loading,
          }
        )}
      >
        {!loading ? (
          <button
            onClick={handleScrollToBottom}
            className="w-full h-full flex items-center justify-center -translate-x-[1px]"
          >
            <ArrowDownIcon />
          </button>
        ) : (
          <SmallLoader className="w-full h-full flex justify-center items-center" />
        )}
      </div>

      <Image.PreviewGroup
        preview={{
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: {
                onActive,
                onFlipY,
                onFlipX,
                onRotateLeft,
                onRotateRight,
                onZoomOut,
                onZoomIn,
                onReset,
              },
            }
          ) => (
            <Space size={24} className="toolbar-wrapper">
              <LeftOutlined onClick={() => onActive?.(-1)} />
              <RightOutlined onClick={() => onActive?.(1)} />
              <DownloadOutlined onClick={onDownload} />
              <SwapOutlined rotate={90} onClick={onFlipY} />
              <SwapOutlined onClick={onFlipX} />
              <RotateLeftOutlined onClick={onRotateLeft} />
              <RotateRightOutlined onClick={onRotateRight} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
              <UndoOutlined onClick={onReset} />
            </Space>
          ),
          onChange: (index) => {
            setCurrent(imageList[index].url);
          },
        }}
      >
        <div className="flex flex-col gap-2 mb-auto">
          {channelMessages?.map((message, index) => {
            const hasNextMessageFromSameUser =
              index === 0
                ? false
                : channelMessages[index]?.sender?.id === channelMessages[index - 1]?.sender?.id;

            const currentTimeMessage = dayts(channelMessages[index]?.created_at).unix();
            const nextTimeMessage = dayts(channelMessages[index - 1]?.created_at).unix();
            const shouldShowTime = index === 0 ? true : currentTimeMessage - nextTimeMessage > 1800;

            return (
              <Message
                key={message?.created_at}
                index={index}
                message={message}
                shouldShowTime={shouldShowTime}
                hasNextMessageFromSameUser={hasNextMessageFromSameUser}
                onClickImg={onClickImg}
              />
            );
          })}
        </div>
      </Image.PreviewGroup>
    </div>
  );
};
