import { useEffect, useState } from 'react';
import { WsListenerType } from '../constants';
import { ISubcribeParams } from '../modules/Emitter';

interface IUseBaseIsConnected {
  baseIsConnected: boolean;
  subscribe: (params: ISubcribeParams) => any;
}

export const useBaseIsWsConnected = ({ baseIsConnected, subscribe }: IUseBaseIsConnected) => {
  const [_, reRender] = useState({});

  useEffect(() => {
    const listener = () => reRender({});
    const unSubcribe = subscribe({
      type: WsListenerType.CONNECT,
      listener,
    });

    return () => {
      unSubcribe();
    };
  }, []);

  return baseIsConnected;
};
