import { WsListenerType } from '../constants';
import { IChannelID, WsCallback, WSMapCallback } from '../interfaces';
import { trueTypeOf } from '../utils';
import { Logger } from './Logger';

export type ISubcribeParams = {
  type: WsListenerType;
  listener: WsCallback;
  channelID?: IChannelID;
  onUnsubscribe?: () => void;
};

export class Emitter {
  private Logger: Logger;
  private listeners: Record<WsListenerType, WSMapCallback | WsCallback[]>;

  constructor(_logger: Logger) {
    this.Logger = _logger;
    this.listeners = {
      [WsListenerType.MESSAGES]: new Map(),
      [WsListenerType.CHANNELS]: [],
      [WsListenerType.CONNECT]: [],
      [WsListenerType.CHANNEL_MESSAGE_MODE]: new Map(),

      [WsListenerType.INTERNAL_GETTING_GROUP_PATH]: [],
      [WsListenerType.INTERNAL_GETTING_MESSAGE]: [],
    };
  }

  public emitChange = (
    type: WsListenerType,
    params?: {
      channelID?: IChannelID;
      data?: any;
    }
  ) => {
    const { data, channelID } = params || {};

    this.Logger.info(
      `Emit event ${type?.toUpperCase()} to listeners`,
      params?.channelID && `- ChannelID: ${channelID}`
    );

    if (trueTypeOf(this.listeners[type]) === 'array') {
      this.listeners[type].forEach((listener) => listener(data));
      return;
    }

    // Only emit change to component subcribe specific channelID in order to prevent re-render all components
    const listeners = (this.listeners[type] as WSMapCallback).get(channelID);
    if (listeners) listeners.forEach((listener) => listener(data));
  };

  public subscribe = ({ type, listener, channelID, onUnsubscribe }: ISubcribeParams) => {
    if (trueTypeOf(this.listeners[type]) === 'array') {
      (this.listeners[type] as WsCallback[]).push(listener);
      return () => {
        (this.listeners[type] as WsCallback[]) = (this.listeners[type] as WsCallback[]).filter(
          (l) => l !== listener
        );
      };
    }

    const listeners = (this.listeners[type] as WSMapCallback).get(channelID);
    const newListeners = listeners ? [...listeners, listener] : [listener];

    (this.listeners[type] as WSMapCallback).set(channelID, newListeners);

    // Unsubscribe and clear cache messages after CACHE_MESSAGES_MS
    return () => {
      (this.listeners[type] as WSMapCallback).set(
        channelID,
        newListeners.filter((l) => l !== listener)
      );

      if (onUnsubscribe) onUnsubscribe();
    };
  };

  public internalSubscribe = (type: WsListenerType, channelID?: IChannelID) => {
    return new Promise((resolve) => {
      if (trueTypeOf(this.listeners[type]) === 'array') {
        (this.listeners[type] as WsCallback[]).push(resolve);
        return;
      }

      const listeners = (this.listeners[type] as WSMapCallback).get(channelID);
      const newListeners = listeners ? [...listeners, resolve] : [resolve];
      (this.listeners[type] as WSMapCallback).set(channelID, newListeners);
    });
  };

  public getListeners = (key: WsListenerType) => this.listeners[key];
}
