import { useEffect, useRef, useState } from 'react';
import { WidgetChat } from '@/modules/public-modules/login/components/chat';
import { chatInstance } from '@/configs/Chat';
import { useQuery } from '@tanstack/react-query';
import { getMenus, ROUTES } from '@/configs/Chat/services/gamp-chat.service';
import { getAnonymousToken, getUserInfo } from '@/services/Auth.service';
import { APIResponse } from '@/interfaces';
import { showMessage } from '@/components/messages/GMessage';
import { useAuth } from '@/configs/Auth/client';

const ChatCSKH = ({ openChat, setOpenChat }) => {
  const hasCreateToken = useRef(false);
  const { handleSetUserInfo, userInfo } = useAuth();

  const { data: menus } = useQuery({
    queryKey: [ROUTES.GET_MENUS],
    queryFn: async () => {
      const res = await getMenus({
        type: 'chat',
      });

      return res.data;
    },

    refetchOnWindowFocus: false,
    enabled: !!userInfo,
  });

  useEffect(() => {
    if (!openChat || hasCreateToken.current) return;

    const handleGetAnonymousToken = async () => {
      const res = await getAnonymousToken();
      if (res?.error) return;

      localStorage.setItem('device_id', res.data?.device_id);
      localStorage.setItem('token', res.data?.access_token);

      const userInfo = await handleSetUserInfo();
      chatInstance.initializeWs(userInfo as any);
    };

    handleGetAnonymousToken();
    hasCreateToken.current = true;
  }, [openChat, handleSetUserInfo]);

  useEffect(() => {
    if (!menus) return;
    const groupPath = menus?.[0]?.group_path;
    if (groupPath) chatInstance.handleSetGroupPath(groupPath);
  }, [menus]);

  return (
    <>
      <div className="py-4 text-center">
        <button
          className="text-th-primary"
          onClick={() => {
            setOpenChat(true);
          }}
        >
          Chat CSKH
        </button>{' '}
        để được hỗ trợ
      </div>
      <WidgetChat
        open={openChat}
        onClose={() => {
          setOpenChat(false);
        }}
      />
    </>
  );
};

export default ChatCSKH;
