/* eslint-disable no-console */
export class Logger {
  private enable: boolean;

  constructor() {
    const localStrEnable = localStorage.getItem('logger');
    this.enable = localStrEnable === '1';
  }

  public info = (message: string, ...rest: any[]) => {
    if (!this.enable) return;
    console.log(`[Chat Module] ${message}`, ...rest);
  };

  public error = (message: string, ...rest: any[]) => {
    if (!this.enable) return;
    console.error(`[Chat Module] ${message}`, ...rest);
  };
}
