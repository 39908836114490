import { IChannelData, IChannelID } from '../interfaces';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ROUTES } from '../services/gamp-chat.service';

interface IUseBaseChannel {
  channelID: IChannelID;
  onGetChannelInfo: (params: { channel_id: IChannelID }) => Promise<IChannelData>;
}

export const useBaseChannel = ({ channelID, onGetChannelInfo }: IUseBaseChannel) => {
  const queryClient = useQueryClient();
  const { data, isFetching } = useQuery<IChannelData>({
    queryKey: [ROUTES.GET_CHANNELS, channelID],
    queryFn: () =>
      onGetChannelInfo({
        channel_id: channelID,
      }),
  });

  const onUpdateChannel = async (newChannel) => {
    queryClient.setQueryData([ROUTES.GET_CHANNELS, channelID], newChannel);
  };

  return {
    detail: data,
    loading: isFetching,
    onUpdateChannel,
  };
};
