export const OS = {
  windowsPhone: 'Windows Phone',
  windows: 'Windows',
  android: 'Android',
  iOS: 'iOS',
  macOS: 'macOS',
  linux: 'Linux',
  unknown: 'unknown',
};

export function getDeviceInfo() {
  if (!navigator) return;

  const userAgent = navigator.userAgent || navigator.vendor;
  let os = OS.unknown;
  let version = '';

  if (/windows phone/i.test(userAgent)) {
    os = OS.windowsPhone;
  } else if (/win/i.test(userAgent)) {
    os = OS.windows;
    const match = userAgent.match(/Windows NT (\d+\.\d+)/);
    if (match) version = match[1];
  } else if (/android/i.test(userAgent)) {
    os = OS.android;
    const match = userAgent.match(/Android\s+([\d.]+)/);
    if (match) version = match[1];
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    os = OS.iOS;
    const match = userAgent.match(/OS\s([\d_]+)/);
    if (match) version = match[1].replace(/_/g, '.');
  } else if (/mac/i.test(userAgent)) {
    os = OS.macOS;
    const match = userAgent.match(/Mac OS X\s+([\d_]+)/);
    if (match) version = match[1].replace(/_/g, '.');
  } else if (/linux/i.test(userAgent)) {
    os = OS.linux;
  }

  return { os, version };
}
