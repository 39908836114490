export const Json = {
  parse: (str: string) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  },

  stringify: (obj: any) => {
    try {
      return JSON.stringify(obj);
    } catch (e) {
      return null;
    }
  },
};
