import ApiClient from '@/configs/ApiClient';

const GATE_WAY_URL = process.env.NEXT_PUBLIC_GAM_GATEWAY;
const api = new ApiClient(GATE_WAY_URL).getInstance();

export const ROUTES = {
  SHOP_DETAIL: '/solution/api/v1/shops/details',
  GET_CUSTOMER_INFO: '/erp/api/v1/reconciliation-information/customer',
  GET_BANKS: '/erp/api/v1/reconciliation-information/banks',
  GET_BANK_BRANCH: '/erp/api/v1/reconciliation-information/bank-branches',
  SAVE_RECONCILIATION_INFO: '/erp/api/v1/reconciliation-information',
  GET_RECONCILIATION_BILL: '/erp/api/v1/reconciliation/bill',
  CHANGE_PASSWORD: '/iam/api/v1/users/change-password',
  CREATE_OTP: '/otp/api/v1/otp/create',
  GET_CONTACT_FILE: '/solution/api/v1/cis/instance/contract/get-file',
  SIGN_CONTRACT: '/solution/api/v1/cis/instance/contract/shop/sign',
  VIEW_FULL_PHONE: '/solution/api/v1/shops/view-true-phone',
};

export const getShopDetail = () => api.get(ROUTES.SHOP_DETAIL);

interface GetCustomerInfoParams {
  customer_code: number;
  partner_id: number;
}

export const getCustomerInfo = (params: GetCustomerInfoParams) =>
  api.get(ROUTES.GET_CUSTOMER_INFO, { params });

interface IGetBanksParams {
  bank_id: number;
}

export const getBanks = () => api.get(ROUTES.GET_BANKS);

export const getBankBranches = (params: IGetBanksParams) => api.get(ROUTES.GET_BANK_BRANCH, { params });

export const saveReconciliationInfo = (data) => api.put(ROUTES.SAVE_RECONCILIATION_INFO, data);

export interface ReconciliationBillsParams {
  text_search?: string;
  status?: number;
  limit: number;
  page: number | string;
}

export const getReconciliationBills = (params: ReconciliationBillsParams) =>
  api.get(ROUTES.GET_RECONCILIATION_BILL, { params });

interface IChangePassword {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

export const changePassword = (params: IChangePassword, otp: string) =>
  api.put(ROUTES.CHANGE_PASSWORD, params, {
    headers: {
      'X-Device-Id': localStorage.getItem('device_id'),
      'X-App-Version': process.env.buildId,
      'X-Otp-Code': otp,
    },
  });

interface ICreateOtp {
  username: string;
}

export const createOtp = (params: ICreateOtp) =>
  api.post(ROUTES.CREATE_OTP, params, {
    headers: {
      'X-Device-Id': localStorage.getItem('device_id'),
      'X-App-Version': process.env.buildId,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

interface IGetContactFile {
  contract_id: number;
}

export const getContactFile = (params: IGetContactFile, username: string) =>
  api.get(ROUTES.GET_CONTACT_FILE, {
    params,
    headers: {
      'X-Username': username,
    },
  });

interface ISignContract {
  subject_solution_id: number;
}

export const signContract = (params: ISignContract, { username, otp }) =>
  api.post(ROUTES.SIGN_CONTRACT, params, {
    headers: {
      'X-Username': username,
      'X-Otp-Code': otp,
    },
  });

interface IViewFullPhone {
  type: 'subject';
}

export const viewFullPhone = (params: IViewFullPhone, username: string) =>
  api.get(ROUTES.VIEW_FULL_PHONE, {
    params,
    headers: {
      'X-Username': username,
    },
  });
