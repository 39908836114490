import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ConfigProvider } from '@/UI';
import {
  COLOR_MODE,
  COLORS,
  getAntdTheme,
  INITIAL_COLOR_MODE,
  ThemeDisplayName,
} from '@/configs/themeConfig';
import { theme as antdTheme } from 'antd';
import vn from 'antd/locale/vi_VN';

interface IThemeContext {
  theme: ThemeDisplayName;
  // eslint-disable-next-line no-unused-vars
  toggleTheme: (e: ButtonMouseEvent, theme?: ThemeDisplayName) => void;
}

interface IMousePosition {
  x: number;
  y: number;
}

type ButtonMouseEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;

function getAllColors(theme: ThemeDisplayName) {
  const themeColors = Object.entries(COLORS)?.reduce((acc, [colorName, colorValues]) => {
    acc[colorName] = colorValues?.[theme];
    return acc;
  }, {} as Record<string, string>);

  return themeColors;
}

const disableTransition = () => {
  const css = document.createElement('style');
  css.appendChild(
    document.createTextNode(
      // eslint-disable-next-line max-len
      '*,*::before,*::after{-webkit-transition:none!important;-moz-transition:none!important;-o-transition:none!important;-ms-transition:none!important;transition:none!important}'
    )
  );
  document.head.appendChild(css);

  return () => {
    // Force restyle
    (() => window.getComputedStyle(document.body))();

    // Wait for next tick before removing
    setTimeout(() => {
      document.head.removeChild(css);
    }, 1);
  };
};

const withThemeTransition = async (
  mousePos: IMousePosition,
  newTheme: ThemeDisplayName,
  callback: (newTheme: ThemeDisplayName) => void
) => {
  if (!document?.startViewTransition) return callback(newTheme);

  const { x, y } = mousePos;
  const endRadius = Math.hypot(Math.max(x, innerWidth - x), Math.max(y, innerHeight - y));

  const clipPath = [`circle(0 at ${x}px ${y}px)`, `circle(${endRadius}px at ${x}px ${y}px)`];

  const transition = document.startViewTransition(() => {
    callback(newTheme);
  });

  await transition.ready.then(() => {
    document.documentElement.animate(
      {
        clipPath: newTheme === 'light' ? clipPath.reverse() : clipPath,
      },
      {
        duration: 500,
        easing: 'ease-in-out',
        pseudoElement: newTheme === 'light' ? '::view-transition-old(root)' : '::view-transition-new(root)',
      }
    );
  });
};

const ThemeContext = React.createContext<IThemeContext>({} as IThemeContext);

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState<ThemeDisplayName>('light');

  useEffect(() => {
    const root = window.document.documentElement;
    const initialColorValue = root.style.getPropertyValue(INITIAL_COLOR_MODE);

    setTheme(initialColorValue as ThemeDisplayName);
  }, []);

  const baseChangeTheme = useCallback((newTheme: ThemeDisplayName) => {
    setTheme(newTheme);

    const root = document.documentElement;
    localStorage.setItem(COLOR_MODE, newTheme);

    Object.entries(COLORS).forEach(([name, colorByTheme]) =>
      root.style.setProperty(`--color-${name}`, colorByTheme[newTheme])
    );
    if (newTheme === 'dark') root.classList.add(newTheme);
    else root.classList.remove('dark');

    disableTransition()();
  }, []);

  const toggleTheme = useCallback(
    (e: ButtonMouseEvent) => {
      const newTheme = theme === 'dark' ? 'light' : 'dark';
      return withThemeTransition({ x: e.clientX, y: e.clientY }, newTheme, baseChangeTheme);
    },
    [baseChangeTheme, theme]
  );

  const contextValue = useMemo(() => ({ toggleTheme, theme }), [theme, toggleTheme]);
  const colors = getAllColors(theme);

  const customAntdTheme = theme ? getAntdTheme(colors) : {};
  return (
    <ThemeContext.Provider value={contextValue}>
      <ConfigProvider
        theme={{ ...customAntdTheme, algorithm: theme === 'dark' && antdTheme.darkAlgorithm }}
        locale={vn}
      >
        {children}
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const data = React.useContext(ThemeContext);

  if (!data) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }

  return data;
};
