import { IChannelMessage } from '@/configs/Chat/interfaces';
import cn from 'classnames';
import AttachmentPreview from './AttachmentPreview';
import DOMPurify from 'dompurify';
import { SmallLoader } from '@/components/common/SmallLoader';

interface IProps {
  message: IChannelMessage;
  index: number;
  shouldShowTime: boolean;
  hasNextMessageFromSameUser: boolean;
  onClickImg: (id: string) => void;
}

export const Message: React.FC<IProps> = ({
  message,
  shouldShowTime,
  hasNextMessageFromSameUser,
  index,
  onClickImg,
}) => {
  const isCurrentUser = message?.is_current_user;

  return (
    <>
      {shouldShowTime && (
        <div className="created_at text-[12px] text-th-text-hint text-center my-2 select-none">
          {message?.created_at_text}
        </div>
      )}
      <div
        data-message-key={message?.id}
        data-index={index}
        className={cn('flex flex-col mb-2', {
          'items-end': isCurrentUser,
          'items-start': !isCurrentUser,
        })}
      >
        <div className="relative flex flex-col items-end">
          {!isCurrentUser && (shouldShowTime || !hasNextMessageFromSameUser) && (
            <div className="username text-[11px] text-th-gray-500 text-left select-none">
              {message?.sender?.fullname}
            </div>
          )}
          <MessageContent onClickImg={onClickImg} message={message} isCurrentUser={isCurrentUser} />
          {/* No score means the message is sending */}
          {!message?.score && <SmallLoader className="absolute top-1/2 -left-5 -translate-y-1/2" />}
        </div>
      </div>
    </>
  );
};

interface IMessageContentProps {
  message: IChannelMessage;
  isCurrentUser: boolean;
  onClickImg: (id: string) => void;
}

const MessageContent: React.FC<IMessageContentProps> = ({ message, isCurrentUser, onClickImg }) => {
  if (message?.attachments?.length) {
    const messagesImgs = message.attachments.filter((attachment) => attachment?.mime?.includes('image'));
    const otherAttachments = message.attachments.filter((attachment) => !attachment?.mime?.includes('image'));

    return (
      <>
        <div
          className={cn('flex gap-1 flex-wrap max-w-[210px] mb-1', {
            'justify-end': isCurrentUser,
            'justify-start': !isCurrentUser,
          })}
        >
          {messagesImgs.map((attachment, index) => (
            <AttachmentPreview
              onClickImg={onClickImg}
              length={message.attachments.length}
              key={index}
              attachment={attachment}
            />
          ))}
        </div>
        <div className="max-w-[250px] flex flex-col gap-1">
          {otherAttachments.map((attachment, index) => (
            <AttachmentPreview key={index} attachment={attachment} length={message.attachments.length} />
          ))}
        </div>
      </>
    );
  }

  return (
    <div
      className={cn('p-2 rounded-lg max-w-[250px]', {
        'bg-th-primary text-th-background': isCurrentUser,
        'bg-th-background': !isCurrentUser,
      })}
      style={{ wordBreak: 'break-word' }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            message?.text
              ?.replace(/\n/g, '<br />')
              .replace(
                /(https?:\/\/[^\s]+)/g,
                '<a href="$1" class="underline text-inherit" target="_blank">$1</a>'
              )
          ),
        }}
      />
    </div>
  );
};
