import dayts from '@/utils/time';
import { IChannelID, IChannelMessage, IMisc } from '../interfaces';

type IValueTypes = 'string' | 'number' | 'boolean' | 'object' | 'array' | 'null' | 'undefined' | 'function';

export const trueTypeOf = (value: any): IValueTypes =>
  Object.prototype.toString.call(value).slice(8, -1).toLowerCase();

export const formatTime = (time?: string): string => {
  if (!time) return '';

  const date = dayts(time)._time;
  const now = dayts()._time;

  if (date.isSame(now, 'day')) return `Hôm nay, ${date.format('HH:mm')}`;
  if (date.isSame(now.add(-1, 'day'), 'day')) return `Hôm qua, ${date.format('HH:mm')}`;
  return date.format('DD/MM/YYYY HH:mm');
};

export const convertMessages = (messages: IChannelMessage[], current: IMisc): IChannelMessage[] => {
  if (!messages) return [];

  return messages.reverse().map((message) => ({
    ...message,
    is_current_user: message?.sender?.id === current?.user?.user_id,
    created_at_text: formatTime(message?.created_at),
  }));
};

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const uid = (len) => {
  const buf = [],
    chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
    charlen = chars.length;
  for (let i = 0; i < len; ++i) buf.push(chars[getRandomInt(0, charlen - 1)]);
  return buf.join('');
};

// Must run AFTER emiting changes
export const scrollToBottom = async (channelID: IChannelID, distance?: number) => {
  const element = document.getElementById(`chat-wrapper-${channelID}`);

  if (!element) return;

  if (distance) {
    if (element && element.scrollHeight - element.scrollTop - element.clientHeight < distance) {
      scrollToBottom(channelID);
    }
    return;
  }

  element.scrollTo(0, element.scrollHeight);
};

export const getImgByMimeType = (mimeType) => {
  if (mimeType.includes('video/')) {
    return '/imgs/ic_file_video.png';
  }

  if (mimeType.includes('application/x-rar-compressed')) {
    return '/imgs/ic_file_rar.png';
  }

  switch (mimeType) {
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return '/imgs/ic_file_word.png';

    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return '/imgs/ic_file_excel.png';

    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      return '/imgs/ic_file_pp.png';

    case 'text/plain':
      return '/imgs/ic_file_text.png';

    case 'application/pdf':
      return '/imgs/ic_file_pdf.png';

    default:
      return '/imgs/ic_file_default.png';
  }
};

export const sortMsgData = (list) => list?.sort((a, b) => Number(a?.id) - Number(b?.id));

export const isValidJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getSearchParams = () => {
  if (typeof window !== 'undefined') {
    const query = new URLSearchParams(window.location.search);
    return {
      channelId: query?.get('channel_id'),
      userId: query?.get('user_id'),
    };
  }

  return {};
};
